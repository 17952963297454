import { useEffect } from "react";
import { useState } from "react";
import { CONNECT_WALLET } from "./metamask/connector";
import { useDispatch } from "react-redux";
import { setLoginDetails } from "../redux/counterSlice";
import { useRouter } from "next/router";
import { Spin } from "antd";
import { Warning } from "../utils/errors";

const WalletWrapper = ({children}) => {
  const [loader, setLoader] = useState(true)
  const {push} = useRouter();
  const dispatch = useDispatch();
    const connectWallet = async () => {
      let user = localStorage.getItem('artnxsUser');
      if(user && user !== null) {
        const res = await CONNECT_WALLET();
        if (res.status) {
          try {
            //  let user = localStorage.getItem('artnxsUser');
            if (user && user !== undefined && user !== 'undefined') {
              user = JSON.parse(user);
              dispatch(setLoginDetails({
                address: res.address,
                balance: res.balance,
                isLogin: true,
                profile: user
              }));
            } else {
              Warning(res.message)
            }
          } catch (error) {
            localStorage.removeItem('artnxsUser')
          }
        } 
        setLoader(false)
      } else {
        setLoader(false)
      }
      }
     
    
  
  useEffect(() => {
   connectWallet()
  }, [])
  if(loader) {
    return <>
      <div className="w-full h-screen flex items-center justify-centers"></div>
    </>
  }
  return (
    <>{children}</>
  )
}

export default WalletWrapper