import Image from "next/image"
import Link from "next/link"
import { BASEURL_IMG } from "../../utils/constant"
import Loading from "../loaders/Loading"
import CustomImage from "../global/CustomImage"
import { useEffect } from "react"
import { useState } from "react"

const SearchModal = ({ isFetching, data }) => {
    const [state, setState] = useState(false);
    const handleClickOutside = (event) => {
        if (event.target.getAttribute('id') !== 'search-input') {
            setState(false)
        }
    }
    useEffect(() => {
        if (data?.status) {
            document.addEventListener("click", handleClickOutside);
        }
        if (data?.status && (data?.nftList?.length > 0 || data?.collectionList?.length > 0)) {
            setState(true)
        }
    }, [data])
    
    return state ? <div className={`absolute  top-full mt-2 left-0 right-0 w-full bg-white dark:bg-jacarta-700 rounded-lg shadow-md z-[99999] ${data?.status && data?.nftList?.length > 0 && data?.collectionList?.length > 0 ? 'max-h-[400px] min-h-[400px] h-full' : ''} overflow-x-auto scrollbar-custom`}>
        {isFetching ? <div className="p-3"><Loading width={30} /></div> : data?.status && <div>
            {data?.nftList?.length > 0 && <div>
                <span className="text-xs font-semibold uppercase text-[#545454] block p-3 dark:text-white">NFTs</span>
                {data?.nftList?.map(nft => (
                    <Link href={`/item/${nft?.id}`} key={nft?.id} className="flex space-x-3 hover:bg-[#f9fafb] p-3 transition-all duration-300 cursor-pointer focus:text-black dark:hover:bg-jacarta-500">
                        <span className="relative w-[30px] h-[30px]">
                            {/* <Image src={`${BASEURL_IMG}${nft?.localFile}`} fill className="w-full h-full object-cover rounded-full" /> */}
                            <CustomImage src={`${BASEURL_IMG}${nft?.localFile}`} fallback={'/images/logo.png'} loaderWidth="20" />
                        </span>
                        <div>
                            <h3 className="text-sm font-semibold capitalize text-black text-[#121212] dark:text-white">{nft?.name}</h3>
                            <span className="text-sm text-[#545454] dark:text-white capitalize">{nft?.price}
                                <span className="text-xs font-medium text-green ml-[4px] inline-block">MATIC</span></span>
                        </div>
                    </Link>
                ))}
            </div>}
        </div>}
        {!isFetching && data?.status && data?.collectionList?.length > 0 && <div className="mt-4">
            <span className="text-xs font-semibold uppercase text-[#545454] block p-3 dark:text-white">Collections</span>
            {data?.collectionList?.map(collection => (
                <Link key={collection?.id} href={`/collection/${collection?.id}`} className="flex space-x-3 hover:bg-[#f9fafb] p-3 transition-all duration-300 cursor-pointer focus:text-black dark:hover:bg-jacarta-500">
                    <span className="relative w-[30px] h-[30px]">

                        <CustomImage src={`${BASEURL_IMG}${collection?.logo}`} fallback={'/images/logo.png'} loaderWidth="20" />
                    </span>
                    <div className="w-full">
                        <h3 className="text-sm font-semibold capitalize dark:text-white text-black text-[#121212]">{collection?.collectionName}</h3>
                        <div className="flex justify-between space-x-4 w-full mt-2">
                            <span className="flex items-center space-x-2">
                                <span className="relative w-4 h-4">
                                    <Image src="/polygon.png" fill className="w-full h-full object-cover" />
                                </span>
                                <span className="text-sm text-[#545454] font-medium capitalize dark:text-white">Items {collection?.totalItem}</span>
                            </span>
                            <span className="text-sm text-[#545454] dark:text-white font-medium capitalize">{collection?.marketCap}</span>

                        </div>
                    </div>
                </Link>
            ))}
        </div>}
    </div> : ''
}

export default SearchModal