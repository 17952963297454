
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useTheme } from "next-themes";
import NextNProgress from "nextjs-progressbar";
const ProgressWrapper = () => {
    const { theme } = useTheme();
    return <NextNProgress color={theme === "light" ? "black" : "white"}/>;
};
export default ProgressWrapper;

    async function __Next_Translate__getStaticProps__18dc829ecbb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/ProgressWrapper',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18dc829ecbb__ as getStaticProps }
  