const footerMenuList = [
  {
    id: 1,
    title: "marketplace",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/nft",
        text: "all_nfts",
      },

      {
        id: 5,
        href: "/collection/explore_collection",
        text: "collections",
      },
    ],
  },
  {
    id: 2,
    title: "company",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/collection/explore_collection",
        text: "explore",
      },
      {
        id: 2,
        href: "/about",
        text: "about",
      },
    ],
  },
  {
    id: 3,
    title: "my_account",
    diffClass: "",
    list: [
      {
        id: 4,
        href: "/create",
        text: "create_nft",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
    image: "/images/social-media/facebook.png",
  },
  {
    id: 2,
    href: "https://twitter.com/ArtNxs_",
    text: "twitter",
    image: "/images/social-media/x.webp",
  },
  {
    id: 3,
    href: "https://discord.gg/8E2X2vqbSf",
    text: "discord",
    image: "/images/social-media/discord.svg",
  },
  {
    id: 4,
    href: "https://www.instagram.com/artnxs.io/",
    text: "instagram",
    image: "/images/social-media/instagram.png",
  },
  {
    id: 5,
    href: "https://www.tiktok.com",
    text: "tiktok",
    image: "/images/social-media/tiktok.png",
  },
];

export { footerMenuList, socialIcons };
