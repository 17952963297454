import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from "./counterSlice";
// import api's actions
import collection from "./actions/collection";
import profile from "./actions/profile";
import nft from "./actions/nft";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [collection.reducerPath]: collection.reducer,
    [profile.reducerPath]: profile.reducer,
    [nft.reducerPath]: nft.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      collection.middleware,
      profile.middleware,
      nft.middleware,
    ]);
  },
});
