module.exports = {
    locales: ["en", "es", "zh", "it", "fr"],
    defaultLocale: "en",
    pages: {
      "*": ["common"],
      "/": ["home"],
      "/about": ["about"],
      "/collection/create": ["created"],
      "/collection/edit/[collectionId]": ["created"],
      "/nft/[nftId]": ["created"],
      "/create": ["created"],
      "/nft": ["explore"],
      "/collection/explore_collection": ["explore"],
      "/collection/[collection]": ["collection"],
      "/item/[item]": ["nft"],
      "/user/[user]": ["user", "explore", "collection"]
    },
    loadLocaleFrom: (lang, ns) =>
      import(`./public/locales/${lang}/${ns}.json`).then((m) => m.default),
  };
  