import { useEffect, useState } from "react"
import SearchModal from "../modal/SearchModal"
import { useNFTAndCollectionSearchQuery } from "../../redux/actions/nft";

const SearchInput = ({mobile = false, placeholder = 'Search'}) => {
  const [text, setText] = useState('');
  const { data, isFetching, isError, error } = useNFTAndCollectionSearchQuery({ text }, {
    skip: !text || text.trim() === '' ? true : false
  })


  return (
    <form className={mobile ? "relative pt-[20px] mt-[90px] mb-[20px]" : "relative"}>
      <input
        type="search"
        className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:bg-white/[.15] dark:text-white dark:placeholder-white shadow-glow border-accent-dark"
        placeholder={placeholder}
        id="search-input"
        value={text}
        onChange={e => setText(e.target.value)}
      />      
      <SearchModal isFetching={isFetching} data={data} />
    </form>
  )
}

export default SearchInput