import { useTheme } from 'next-themes';
import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

const Loading = ({width}) => {
  const { theme, setTheme } = useTheme();
  return (
    <RotatingLines
        strokeColor={theme === 'light' ? 'black' : 'white'}
        strokeWidth="5"
        animationDuration="0.75"
        width={width}
        visible={true}
      />
  )
}

export default Loading