import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASEURL } from "../../utils/constant";
export const nft = createApi({
  reducerPath: "nft",
  tagTypes: ["nft", "collection"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
  }),
  endpoints: (builder) => ({
    createNFT: builder.mutation({
      query: (data) => {
        return {
          url: `nft/create`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["nft"],
    }),
    NFTLike: builder.mutation({
      query: (data) => {
        return {
          url: `nft/like`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["nft"],
    }),
    userNFTList: builder.query({
      query: (data) => {
        return {
          url: `nft/userNftList`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["nft"],
    }),
    updateNFT: builder.mutation({
      query: (data) => {
        return {
          url: `nft/update`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["nft"],
    }),
    bidList: builder.mutation({
      query: (data) => {
        return {
          url: `nft/getBidList`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["nft"],
    }),
    UserCreatedNFT: builder.query({
      query: (data) => {
        return {
          url: `nft/createdList`,
          method: "POST",
          body: data,
        };
      },
      providesTags: ["nft"],
    }),
    NFTDetails: builder.query({
      query: ({ id }) => {
        return {
          url: `explorer/nft/details/${id}`,
          method: "GET",
        };
      },
      providesTags: ["nft"],
    }),
    NFTList: builder.query({
      query: (data) => {
        return {
          url: `explorer/nft/list`,
          method: "POST",
          body: data,
        };
      },
      providesTags: ["nft"],
    }),
    NFTPriceChart: builder.query({
      query: ({ nft_id }) => {
        return {
          url: `explorer/nft/chart/${nft_id}`,
          method: "GET",
        };
      },
      providesTags: ["nft"],
    }),
    NFTAndCollectionSearch: builder.query({
      query: ({ text }) => {
        return {
          url: `/home/mainSearch/${text}`,
          method: "GET",
        };
      },
      providesTags: ["nft"],
    }),
    HotNFTList: builder.query({
      query: (user_id) => {
        return {
          url: `home/topNft/${user_id}`,
          method: "GET",
        };
      },
      providesTags: ["nft"],
    }),
    TrandingNFT: builder.query({
      query: (data) => {
        return {
          url: `home/trandingNFT`,
          method: "POST",
          body: data,
        };
      },
      providesTags: ["nft"],
    }),
    UserStatistics: builder.query({
      query: (user_id) => {
        return {
          url: `/user/statistics/${user_id}`,
          method: "GET",
        };
      },
      providesTags: ["nft", "collection"],
    }),
    UserFavouritenft: builder.query({
      query: (user_id) => {
        return {
          url: `/nft/favouritenft/${user_id}`,
          method: "GET",
        };
      },
      providesTags: ["nft", "collection"],
    }),
  }),
});
export const {
  useCreateNFTMutation,
  useNFTLikeMutation,
  useUserNFTListQuery,
  useUpdateNFTMutation,
  useBidListMutation,
  useUserNFTQuery,
  useNFTDetailsQuery,
  useNFTListQuery,
  useNFTPriceChartQuery,
  useNFTAndCollectionSearchQuery,
  useHotNFTListQuery,
  useTrandingNFTQuery,
  useUserCreatedNFTQuery,
  useUserStatisticsQuery,
  useUserFavouritenftQuery
} = nft;

export default nft;
