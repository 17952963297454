import { useTheme } from "next-themes";
import Skeleton from '@mui/material/Skeleton';
import { useEffect } from "react";
import { useState } from "react";
import { Img } from "react-image";
import { RotatingLines } from  'react-loader-spinner'

const CustomImage = ({src, fallback, loaderWidth='w-full', ratio=false, className, banner, rounded= true, card=false, custom=false, customSize,loaderClasses,loaderHeight, NftCard=false}) => {
  const { theme, setTheme } = useTheme();
  return (
    <Img
    className={`${rounded && 'rounded-lg'} ${className} ${ratio ? 'w-full h-full absolute inset-0 object-cover overflow-hidden' : 'w-full h-full object-cover overflow-hidden'} ${NftCard ? '!object-contain' : ''}`}
    src={[src, src]}
    loader={banner ? <div className="skeleton-image"></div> : card ? <Skeleton variant="rectangular" className="!w-full" height={230} /> : custom && customSize ? <Skeleton variant="rectangular" className={`${customSize}`} />  :  <div className="w-full h-full flex items-center justify-center">
    <Skeleton variant="rectangular" className={loaderWidth} height={loaderHeight} />
</div>}
  />
  )
}

export default CustomImage