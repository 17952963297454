import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASEURL } from "../../utils/constant";
export const profile = createApi({
  reducerPath: "profile",
  tagTypes: ["profile"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
  }),
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: ({ id }) => {
        return {
          url: `user/getProfile/${id}`,
          method: "GET",
        };
      },
      providesTags: ["profile"],
    }),
    userActivities: builder.query({
      query: (data) => {
        return {
          url: `user/activity`,
          method: "POST",
          body: data,
        };
      },
      providesTags: ["profile"],
    }),
    updateProfile: builder.mutation({
      query: (data) => {
        return {
          url: `user/updateProfile`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["profile"],
    }),
    
  }),
});
export const {
  useGetUserProfileQuery,
  useUserActivitiesQuery,
  useUpdateProfileMutation,
  
} = profile;

export default profile;
