// export const BASEURL = "http://localhost:8080/api/"
export const BASEURL = "https://admin.artnxs.io/api/";
export const WEBSITE = "https://artnxs.io";
// export const BASEURL_IMG = "https://admin.artnxs.io/api/uploads/";
export const BASEURL_IMG  = "https://artnft.s3.amazonaws.com/";
export const BASEURL_MINT = "https://mumbai.polygonscan.com/address/";
export const IPFS_BASE = "https://ipfs.io/ipfs/";

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const DATALAKE_URL = process.env.REACT_APP_VERSION;
