
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef, useState, useEffect } from "react";
import WalletWrapper from "../components/WalletWrapper";
import ProgressWrapper from "./ProgressWrapper";
if (typeof window !== "undefined") {
    require("bootstrap/dist/js/bootstrap");
}
function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const [showChild, setShowChild] = useState(false);
    const pid = router.asPath;
    const scrollRef = useRef({
        scrollPos: 0
    });
    useEffect(() => {
        setShowChild(true);
    }, []);
    if (!showChild) {
        return null;
    }
    return (<>
      <Meta title="Home 1"/>
      <link rel="icon" href="/favicon.jpg" sizes="any"/>

      <Provider store={store}>
        <WalletWrapper>
          <ThemeProvider enableSystem={true} attribute="class">
            <ProgressWrapper />
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === "/login" ? (<Component {...pageProps}/>) : (<Layout>
                    <Component {...pageProps}/>
                  </Layout>)}
              </UserContext.Provider>
            </MetaMaskProvider>
          </ThemeProvider>
        </WalletWrapper>
      </Provider>
    </>);
}
const __Next_Translate__Page__18dc829eca6__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18dc829eca6__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  