import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASEURL } from "../../utils/constant";
export const collection = createApi({
  reducerPath: "collection",
  tagTypes: ["collection"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
  }),
  endpoints: (builder) => ({
    updateCollection: builder.mutation({
      query: (data) => {
        return {
          url: `collection/update`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["collection"],
    }),
    userCollectionsList: builder.query({
      query: ({ id }) => {
        return {
          url: `collection/list/${id}`,
          method: "GET",
        };
      },
      providesTags: ["collection"],
    }),
    explorerCollections: builder.query({
      query: (data) => {
        return {
          url: `explorer/collection/list`,
          method: "POST",
          body: data,
        };
      },
      providesTags: ["collection"],
    }),
    collectionDetails: builder.query({
      query: ({ id }) => {
        return {
          url: `explorer/collection/details/${id}`,
          method: "GET",
        };
      },
      providesTags: ["collection"],
    }),
    collectionItems: builder.query({
      query: (data) => {
        return {
          url: `explorer/nftListByCollection`,
          method: "POST",
          body: data,
        };
      },
      providesTags: ["collection"],
    }),
    categoryList: builder.query({
      query: () => {
        return {
          url: `explorer/category/list`,
          method: "GET",
        };
      },
      providesTags: ["collection"],
    }),
    topCollections: builder.query({
      query: ({ days }) => {
        return {
          url: `home/topCollection/${days}`,
          method: "GET",
        };
      },
      providesTags: ["collection"],
    }),
    CollectionActivities: builder.query({
      query: (data) => {
        return {
          url: `explorer/collection/activity`,
          method: "POST",
          body: data,
        };
      },
      providesTags: ["collection"],
    }),
  }),
});
export const {
  useUserCollectionsListQuery,
  useCollectionDetailsQuery,
  useExplorerCollectionsQuery,
  useCollectionItemsQuery,
  useUpdateCollectionMutation,
  useCategoryListQuery,
  useTopCollectionsQuery,
  useCollectionActivitiesQuery,
} = collection;

export default collection;
