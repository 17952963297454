import Web3 from "web3";

export const CONNECT_WALLET = async () => {
  if (window.ethereum) {
    var metamaskProvider = await window.ethereum;

    try {
      const accounts = await metamaskProvider.request({
        method: "eth_requestAccounts",
      });
      const web3 = new Web3(window.ethereum);
      let bal = await web3.eth.getBalance(accounts[0]);
      return {
        status: true,
        address: accounts[0],
        balance: (parseInt(bal) / 10 ** 18).toFixed(6),
      };
    } catch (err) {
      return {
        status: false,
        message:
          err.message.indexOf("Already processing eth_requestAccounts") > -1 ||
          err.message.indexOf("Request of type") > -1
            ? "Unable to establish a connection with your Metamask wallet. Please ensure  your wallet is connected"
            : err.message,
      };
    }
  } else {
    return {
      status: false,
      message: "Please install Metamask wallet to use this App",
    };
  }
};
